import React from "react";
import { TrafficViewer } from "../../TrafficViewer/TrafficViewer"
import "../../../index.sass"
import { TimezoneModal } from "../../modals/TimezoneModal/TimezoneModal";

export const TrafficPage: React.FC = () => {
  return (
    <>
      <TrafficViewer />
      <TimezoneModal />
    </>
  );
};
