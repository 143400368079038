import { Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import variables from '../../../../variables.module.scss'
import { LicenseEnterpriseEdition, LicenseOndemandEdition, LicenseProEdition, LinkContactUs } from '../../../../consts'
import useLicense from '../../../../hooks/useLicense'

export const LicenseIssueText: React.FC = () => {
  const { licenseInfo: info, commercialEdition, nodeLimitOverflow, apiCapacityDepleted, expired } = useLicense()

  const defaultText: JSX.Element = <>
    <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
      Oops! It seems something went wrong with your license, please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
    </Typography>
  </>

  const expirableEditions = [LicenseEnterpriseEdition, LicenseOndemandEdition]

  const [issueText, setIssueText] = useState(defaultText)

  useEffect(() => {
    if (nodeLimitOverflow && !commercialEdition) {
      // COMMUNITY: node limit overflow
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          - Log in to use your license. <br />
          - A complimentary PRO license is automatically provisioned when you sign up.
        </Typography>
      </>)
    } else if (apiCapacityDepleted && (info.doc.edition == LicenseProEdition)) {
      // PRO: API capacity ran out
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          API call capacity limit reached! 
        </Typography>
      </>)
    } else if (expirableEditions.includes(info.doc.edition) && expired) {
      // ENTERPRISE/ON-DEMAND: expired
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          Your license has expired. 
        </Typography>
      </>)
    } else if (commercialEdition && nodeLimitOverflow && info.maxNodeCount === 0) {
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          Your license is invalid.<br /><br />
          Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
        </Typography>
      </>)
    } else if (commercialEdition && nodeLimitOverflow) {
      // PRO: node limit overflow
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          Node limit reached! 
        </Typography>
      </>)
    } else if ((info.doc.edition != LicenseEnterpriseEdition) && (!navigator.onLine)) {
      // NON-ENTERPRISE: no internet connection
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          No internet connectivity detected. Air-gapped clusters require an ENTERPRISE license. <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>Contact us</Link> to get one.
        </Typography>
      </>)
    } else if (info.doc.edition === "") {
      setIssueText(<>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          There is a failure to read essential license information such as: edition, node limit or expiry date.<br /><br />
          Please <Link href={LinkContactUs} target='_blank' underline='hover' fontWeight={700} color={variables.blueColor}>contact us</Link> for a swift resolution.
        </Typography>
      </>)
    }
  }, [info, commercialEdition, nodeLimitOverflow, apiCapacityDepleted, expired])

  return issueText
}
