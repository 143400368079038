import React, { useEffect, useState } from 'react'
import { LicenseApiCapacityOption, LicenseExpiryOption } from '../../../types/license'

import { Modal, Box, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { LicenseProEdition } from '../../../consts'
import { Utils } from '../../../helpers/Utils'
import timezoneAtom from '../../../recoil/timezone/atom'
import { appCloudLicenseEnabled } from '../../../types/global'
import { LicenseMetric } from './LicenseMetric/LicenseMetric'
import { LicenseIssueCard } from '../../UI/LicenseIssueCard/LicenseIssueCard'
import { ProgressLabeled } from '../../UI/ProgressLabeled/ProgressLabeled'
import { UpgradeLicenseButton } from '../../UI/UpgradeLicenseButton/UpgradeLicenseButton'

import styles from './LicenseInfoModal.module.sass'
import variables from '../../../variables.module.scss'

import KeyRoundedIcon from '@mui/icons-material/KeyRounded'
import AppsRoundedIcon from '@mui/icons-material/AppsRounded'
import MultipleStopRoundedIcon from '@mui/icons-material/MultipleStopRounded'
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded'
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded'
import { LicenseBadge } from './LicenseBadge/LicenseBadge'
import { AuthButtonWide } from '../../UI/AuthButton/AuthButtonWide'

import useLicense from '../../../hooks/useLicense'
import { LicenseIssueText } from './LicenseIssueText/LicenseIssueText'
import { LicenseeInfo } from './LicenseeInfo/LicenseeInfo'
import authSessionOkAtom from '../../../recoil/authSessionOk/atom'

interface ApiCapacityTextProps {
  color: string
  text: string
}

const ApiCapacityText: React.FC<ApiCapacityTextProps> = ({ text, color }) => {
  return (
    <span
      style={{
        color: color,
        fontWeight: 700,
        textTransform: 'uppercase'
      }}
    >
      {text}
    </span>
  )
}

export const LicenseInfoModal: React.FC = () => {
  const [open, setOpen] = useState(false)

  const handleCloseModal = () => {
    setOpen(false)
  }

  const {
    ready: licenseReady,
    licenseInfo: info,
    nodeLimitOverflow,
    expired,
    valid,
    showApiCapacityProgress,
    apiCapacityDepleted,
    commercialEdition,
    apiCapacityOption,
    expiryOption
  } = useLicense()

  useEffect(() => {
    if (open) {
      return
    }

    if (!licenseReady) {
      return
    }

    setOpen(!valid)

    // if (valid && info.maxNodeCount > 0) {
    //   setOpen(nodeLimitOverflow)
    // }
  }, [info, licenseReady])

  const [nodeLimitIconColor, setNodeLimitIconColor] = useState(variables.grayColor)
  const [nodeLimitValueColor, setNodeLimitValueColor] = useState('')

  useEffect(() => {
    setNodeLimitIconColor(
      nodeLimitOverflow ? variables.upgradeColor : variables.grayColor
    )
    setNodeLimitValueColor(
      nodeLimitOverflow ? variables.upgradeColor : variables.successColor
    )
  }, [nodeLimitOverflow])

  const [displayedApiCapacity, setDisplayedApiCapacity] = useState<
    string | number | JSX.Element
  >('')
  const [displayedApiCapacityProgress, setDisplayedApiCapacityProgress] =
    useState<JSX.Element>(null)

  const [expiryIconColor, setExpiryIconColor] = useState(variables.grayColor)
  const [displayedExpiry, setDisplayedExpiry] = useState<string | JSX.Element>('Never')

  // How API capacity & expiry are displayed depending on their options
  useEffect(() => {
    if (apiCapacityOption === LicenseApiCapacityOption.Unlimited) {
      setDisplayedApiCapacity(<ApiCapacityText color={variables.successColor} text='Unlimited' />)
      setDisplayedApiCapacityProgress(null)
    } else if (apiCapacityOption === LicenseApiCapacityOption.Limited) {
      if (apiCapacityDepleted) {
        setDisplayedApiCapacity(<ApiCapacityText color={variables.upgradeColor} text='Depleted' />)
        setDisplayedApiCapacityProgress(null)
        return
      }

      // UI fallback for existing users whose license has `initialCapacity === 0`
      setDisplayedApiCapacity(
        !showApiCapacityProgress
          ? `${Utils.humanReadableNumber(info.doc.capacity)} left`
          : undefined
      )

      if (showApiCapacityProgress) {
        setDisplayedApiCapacityProgress(
          <ProgressLabeled
            current={info.doc.capacity}
            total={info.doc.initialCapacity}
          />
        )
      }
    }
  }, [apiCapacityOption])

  const timezone = useRecoilValue(timezoneAtom)

  useEffect(() => {
    if (!licenseReady) {
      return
    }

    setExpiryIconColor(expired ? variables.upgradeColor : variables.grayColor)

    setDisplayedExpiry(
      <span style={{ color: expired ? variables.upgradeColor : variables.fontColor, fontWeight: 600 }}>
        {Utils.TimezoneMoment(timezone, +info.doc.end).format('MM/DD/YYYY, h:mm A (UTCZ)')}
      </span>
    )
  }, [licenseReady, expired, info])

  const [displayedExpiryTitle, setDisplayedExpiryTitle] =
    useState<string>('Expires')

  useEffect(() => {
    if (!licenseReady) {
      return
    }

    if (expiryOption === LicenseExpiryOption.Endless) {
      if (info.doc.edition === LicenseProEdition) {
        setDisplayedExpiryTitle('Renews')
      } else {
        setDisplayedExpiry('Never')
      }
    }
  }, [licenseReady, expiryOption, info])

  const authSessionOk = useRecoilValue(authSessionOkAtom)

  const [displayedLicenseMetrics, setDisplayedLicenseMetrics] = useState<JSX.Element | JSX.Element[]>(null)

  const resolveDisplayedLicenseMetrics = () => {
    if (!licenseReady) {
      return
    }

    if (info.doc.edition === "") {
      setDisplayedLicenseMetrics(
        <AuthButtonWide size='medium' loginTooltip='Log in to activate your (complimentary) license' />
      )
      return
    }

    setDisplayedLicenseMetrics(<>
      <LicenseMetric
        title='Node limit'
        value={
          <span
            style={{
              fontWeight: 700,
              color: nodeLimitValueColor
            }}
          >
            {info.currentNodeCount}/{info.maxNodeCount}
          </span>
        }
        icon={<AppsRoundedIcon htmlColor={nodeLimitIconColor} />}
        error={nodeLimitOverflow}
      />
      {apiCapacityOption !== LicenseApiCapacityOption.Unset && (
        <LicenseMetric
          title='API calls'
          value={displayedApiCapacity}
          icon={<MultipleStopRoundedIcon htmlColor={variables.grayColor} />}
          horizontalValueElement={displayedApiCapacityProgress}
        />
      )}
      <LicenseMetric
        title={displayedExpiryTitle}
        value={displayedExpiry}
        icon={
          <InsertInvitationRoundedIcon htmlColor={expiryIconColor} />
        }
        error={expired}
      />
      {appCloudLicenseEnabled() && commercialEdition && <LicenseMetric
        title='Licensee information'
        icon={
          <ManageAccountsRoundedIcon htmlColor={variables.grayColor} />
        }
        horizontalValueElement={<LicenseeInfo closeLicenseInfoModal={() => setOpen(false)} />}
      />}
      {appCloudLicenseEnabled() && !commercialEdition && !authSessionOk && <AuthButtonWide
        size='medium'
        loginTooltip='Log in to activate your (complimentary) license'
      />}
    </>)
  }

  useEffect(() => resolveDisplayedLicenseMetrics(), [licenseReady, info])

  return (<>
    <LicenseBadge
      dropdownOpen={open}
      setDropdownOpen={setOpen}
    />
    <Modal open={open} onClose={handleCloseModal} disableAutoFocus>
      <Box className={styles.LicenseInfoModal}>
        <Box className={styles.TitleContainer}>
          <Box display='flex' alignItems='center' gap='10px'>
            <KeyRoundedIcon htmlColor={variables.blueColor} />
            <Typography variant='h6' className={styles.TitleText}>
              {!appCloudLicenseEnabled() && info.doc.edition}
              {appCloudLicenseEnabled() && commercialEdition && info.doc.edition}
              {appCloudLicenseEnabled() && !commercialEdition && 'NO LICENSE DETECTED'}
            </Typography>
          </Box>
          {appCloudLicenseEnabled() && <UpgradeLicenseButton size='small' icon={!(appCloudLicenseEnabled() && !commercialEdition)} />}
          {/* <HelpIconButton
            tooltipTitle='Learn more about other editions'
            link='https://www.kubeshark.co/pricing'
          /> */}
        </Box>
        {!valid && <LicenseIssueCard theme='error' title='License issue' text={<LicenseIssueText />} />}
        {displayedLicenseMetrics}
        {!appCloudLicenseEnabled() && <UpgradeLicenseButton size='medium' fullWidth icon={true} />}
      </Box>
    </Modal>
  </>)
}
