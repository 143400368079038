import React, { useState, useCallback, useEffect, useMemo } from "react"
import SectionsRepresentation from "./SectionsRepresentation";
import { Manifest, Metadata } from "./Kubernetes";
import ErrorSection from "./ErrorSection";
import styles from './EntryViewer.module.sass';
import { Tabs } from "../../UI";
import { Entry, Representation } from "../../EntryListItem/Entry";

export enum TabsEnum {
  Request = 0,
  Response = 1,
  Manifest = 2,
  Metadata = 3,
  Error = 4,
}

interface AutoRepresentationProps {
  representation: Representation;
  data: Entry;
  color: string;
  openedTabIndex?: TabsEnum;
}

export const AutoRepresentation: React.FC<AutoRepresentationProps> = ({ representation, data, color, openedTabIndex = TabsEnum.Request }) => {
  if (data.error !== null) {
    openedTabIndex = TabsEnum.Error
  }

  const TABS = useMemo(() => {
    const arr = [
      {
        tab: 'Request',
        badge: null,
        disabled: representation.request === null,
      },
      {
        tab: 'Response',
        badge: null,
        disabled: representation.response === null,
      },
      {
        tab: 'Manifest',
        badge: null
      },
      {
        tab: 'Metadata',
        badge: null
      }
    ]

    if (data.error !== null) {
      arr.push({
        tab: 'Error',
        badge: null
      })
    }

    return arr
  }, [representation]);

  const [currentTab, setCurrentTab] = useState('');

  const getOpenedTabIndex = useCallback(() => {
    const currentIndex = TABS.findIndex(current => current.tab === currentTab)
    return currentIndex > -1 ? currentIndex : 0
  }, [TABS, currentTab])

  useEffect(() => {
    if (openedTabIndex >= 0 && openedTabIndex < TABS.length) {
      let targetTab = TABS[openedTabIndex]
      if (targetTab?.disabled) {
        targetTab = TABS.find(tab => !tab?.disabled)
      }

      if (targetTab) {
        setCurrentTab(targetTab.tab);
      }
    }
  }, [])

  // Don't fail even if `representation` is an empty string
  if (!representation) {
    return <React.Fragment></React.Fragment>;
  }

  return <div className={styles.Entry}>
    {<div className={styles.body}>
      <div className={styles.bodyHeader}>
        <Tabs tabs={TABS} currentTab={currentTab} color={color} onChange={setCurrentTab} leftAligned />
      </div>
      {getOpenedTabIndex() === TabsEnum.Request && <React.Fragment>
        <SectionsRepresentation data={representation.request} color={color} />
      </React.Fragment>}
      {getOpenedTabIndex() === TabsEnum.Response && <React.Fragment>
        <SectionsRepresentation data={representation.response} color={color} />
      </React.Fragment>}
      {getOpenedTabIndex() === TabsEnum.Manifest && <React.Fragment>
        <Manifest source={data.src} destination={data.dst} color={color} />
      </React.Fragment>}
      {getOpenedTabIndex() === TabsEnum.Metadata && <React.Fragment>
        <Metadata data={data} color={color} />
      </React.Fragment>}
      {getOpenedTabIndex() === TabsEnum.Error && <React.Fragment>
        <ErrorSection error={data.error} color={color} />
      </React.Fragment>}
      <div style={{height: "50px"}}></div>
    </div>}
  </div>;
}
