import Moment from 'moment-timezone';

import variables from '../variables.module.scss'

const IP_ADDRESS_REGEX = /([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3})(:([0-9]{1,5}))?/

type JSONValue =
  | string
  | number
  | boolean
  | unknown

export interface BoolProps {
  [key: string]: boolean;
}

export class Utils {
  static isIpAddress = (address: string): boolean => IP_ADDRESS_REGEX.test(address)
  static lineNumbersInString = (code: string): number => code.split("\n").length;

  static humanFileSize(bytes: number, si = false, dp = 1): string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  // Converts 7250 to 7.25K, 3990245 to 3.99M, etc.
  static humanReadableNumber(number) {
    if (number >= 1e9) {
      return (number / 1e9).toFixed(2).replace(/\.00$/, '') + "B";
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2).replace(/\.00$/, '') + "M";
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(2).replace(/\.00$/, '') + "K";
    }
    return number.toString();
  }

  static padTo2Digits = (num: number): string => {
    return String(num).padStart(2, '0');
  }

  static getHoursAndMinutes = (protocolTimeKey: string): string => {
    const time = new Date(protocolTimeKey)
    const hoursAndMinutes = Utils.padTo2Digits(time.getHours()) + ':' + Utils.padTo2Digits(time.getMinutes());
    return hoursAndMinutes;
  }

  static formatDate = (date: string): string => {
    const d = new Date(date),
      year = d.getFullYear();

    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();

    const hoursAndMinutes = Utils.getHoursAndMinutes(date);
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    const newDate = [year, month, day].join('-');
    return [hoursAndMinutes, newDate].join(' ');
  }

  static createUniqueObjArrayByProp = (objArray: unknown[], prop: string): unknown => {
    const map = new Map(objArray.map((item) => [item[prop], item])).values()
    return Array.from(map);
  }

  static isJson = (str: string): boolean => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  static downloadFile = (data: string, filename: string, fileType: string): void => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    a.remove();
  }

  static exportToJson = (data: JSONValue, name: string): void => {
    Utils.downloadFile(JSON.stringify(data), `${name}.json`, 'text/json')
  }

  static getTimeFormatted = (time: Moment.MomentInput): string => {
    return Moment(time).utc().format('MM/DD/YYYY, h:mm:ss.SSS A')
  }

  static TimezoneMoment = (timezone: string, inp?: Moment.MomentInput): Moment.Moment => {
    const moment = Moment(inp || new Date())
    const timezoneValid = Moment.tz.names().includes(timezone)

    return timezoneValid ? moment.tz(timezone) : moment
  }

  static getNow = (format = 'MM/DD/YYYY, HH:mm:ss.SSS'): string => {
    return Moment().format(format)
  }

  static getGradientColorByPercentage = (percentage: number): string => {
    let r, g, b

    percentage = Math.max(0, Math.min(100, percentage))
    if (percentage <= 50) {
      const factor = percentage / 50

      r = Math.round(219 + (255 - 219) * factor)
      g = Math.round(33 + (215 - 33) * factor)
      b = Math.round(86 * (1 - factor))
    } else {
      const factor = (percentage - 50) / 50

      r = Math.round(255 + (39 - 255) * factor)
      g = Math.round(215 + (174 - 215) * factor)
      b = Math.round(96 * factor)
    }

    return `rgb(${r},${g},${b})`
  }

  static getColorByPercentage = (percentage: number): string => {
    percentage = Math.max(0, Math.min(100, percentage));

    if (percentage <= 33.33) {
      return variables.warningColor
    // } else if (percentage <= 66.66) {
    //   return variables.warningColor
    } else {
      return variables.successColor
    }
  }

  static countTrueProps = (obj: BoolProps): number => {
    return Object.values(obj).reduce((count, value) => {
      return count + (value ? 1 : 0);
    }, 0);
  };

  static truncateString = (str: string, firstCharCount: number = str.length, endCharCount = 0, dotCount = 3) => {
    if (!str?.length) {
      return str
    }

    if (str.length <= firstCharCount + endCharCount) {
      return str;
    }

    const firstPortion = str.slice(0, firstCharCount);
    const endPortion = endCharCount ? str.slice(-endCharCount) : '';
    const dots = '.'.repeat(dotCount);

    return `${firstPortion}${dots}${endPortion}`;
  }
}
