import React from 'react'
import { Descope } from '@descope/react-sdk'
import { toast } from 'react-toastify'
import { setAuthSessionDetails } from '../AuthSessionDetails'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from '../../../../recoil/trafficPlayback/atom'
import { useSetRecoilState } from 'recoil'
import resetLicenseTimersSignalAtom from '../../../../recoil/resetLicenseTimersSignal'

export const DescopeSignInForm: React.FC = () => {
  const setTrafficPlayback = useSetRecoilState(trafficPlaybackAtom);
  const setResetLicenseTimersSignal = useSetRecoilState(resetLicenseTimersSignalAtom)

  return (
    <Descope
      flowId='sign-up-or-in'
      theme='light'
      onError={(err: unknown) => {
        toast.error(err.toString())
        console.error(err)
      }}
      onSuccess={(event: CustomEvent) => {
        const { userId, name, email } = event.detail.user;
        setAuthSessionDetails({ userId, name, email });
        setTimeout(() => setTrafficPlayback(TRAFFIC_PLAYBACK_OK), 500)
        setResetLicenseTimersSignal(Date.now())
      }}
    />
  )
}
