import React from 'react'

import { Box } from '@mui/material'
import Queryable from '../../UI/Queryable/Queryable'

import variables from '../../../variables.module.scss'
import styles from '../TrafficEntry.module.sass'

export type ResourceType = 'name' | 'node' | 'namespace' | 'service' | 'pod'

interface EntryDataProps {
  resource?: ResourceType
  name: string
  query: string
  tooltipStyle?: React.CSSProperties
  tooltipIconStyle?: React.CSSProperties
  extraName?: string
  extraQuery?: string
}

export const EntryData: React.FC<EntryDataProps> = ({
  name,
  query,
  tooltipStyle,
  tooltipIconStyle,
  extraName,
  extraQuery,
}) => {
  return (
    <Box display='flex' alignItems='center'>
      {name && name !== '-' ? (<Queryable
        query={query}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          transform: 'translate(-5px, -10px)',
          ...tooltipIconStyle
        }}
        tooltipStyle={{
          transform: 'translate(15px, -15px)',
          ...tooltipStyle
        }}
      >
        <span className={styles.DataText}>{name}</span>
      </Queryable>) : (
        <Queryable
          query={query}
          displayIconOnMouseOver={true}
          flipped={true}
          style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
          iconStyle={{
            transform: 'translate(-5px, -10px)',
            ...tooltipIconStyle
          }}
          tooltipStyle={{
            transform: 'translate(15px, -15px)',
            ...tooltipStyle
          }}
        >
          <Box
            display='flex'
            alignItems='center'
            gap='2px'
            padding='1px 4px'
            borderRadius='4px'
            border={`2px dashed ${variables.dataBackgroundColor}`}
            width='fit-content'
          >
            <span className={styles.DataText} style={{ color: variables.lightGrayColor }}>
              unresolved
            </span>
          </Box>
        </Queryable>
      )}
      {extraName && extraName !== '-' && <Queryable
        query={extraQuery}
        displayIconOnMouseOver={true}
        flipped={true}
        style={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        iconStyle={{
          position: 'absolute',
          top: '12px'
        }}
      >
        <span className={styles.DataText}>
          {name && name !== '-' ? <>&nbsp;({extraName})</> : <>&nbsp;{extraName}</>}
        </span>
      </Queryable>}
    </Box>
  )
}
