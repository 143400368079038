import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Entry } from '../EntryListItem/Entry'

import variables from '../../variables.module.scss'
import styles from './TrafficEntry.module.sass'

import entryDetailsOpenAtom from '../../recoil/entryDetailsOpen/atom'
import focusedItemAtom from '../../recoil/focusedItem/atom'
import focusedRecordAtom from '../../recoil/focusedRecord/atom'
import focusedStreamAtom from '../../recoil/focusedStream/atom'
import entryDetailsViewModeAtom from '../../recoil/entryDetailsViewMode'
import timezoneAtom from '../../recoil/timezone/atom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'


import Protocol from '../UI/Protocol/Protocol'
import StatusCode from '../UI/StatusCode/StatusCode'
import { TlsIndicator } from './components/TlsIndicator'
import { resolveTrafficEntryStyles } from './helpers'
import { Method } from './components/Method'
import { Route } from './components/Route'
import { FullResolutionData } from './components/FullResolutionData'
import { IpWithPort } from './components/IpWithPort'
import { GenericDirectionIcon } from '../EntryListItem/icons/GenericDirectionIcon'
import { EntryData } from './components/EntryData'
import { Timestamp } from './components/Timestamp'
import useWindowDimensions from '../../hooks/WindowDimensionsHook'
import { DuplicateBadge } from './components/DuplicateBadge'

interface TrafficEntryProps {
  entry: Entry
  duplicateOrigin?: Entry
  headingMode?: boolean
  style?: unknown
}

export const TrafficEntry: React.FC<TrafficEntryProps> = ({
  entry,
  duplicateOrigin = null,
  headingMode = false,
  style
}) => {
  const [focusedItem, setFocusedItem] = useRecoilState(focusedItemAtom)
  const [focusedStream, setFocusedStream] = useRecoilState(focusedStreamAtom)
  const [entryDetailsOpen, setEntryDetailsOpen] = useRecoilState(entryDetailsOpenAtom)
  const setFocusedRecord = useSetRecoilState(focusedRecordAtom)

  const entryDetailsViewMode = useRecoilValue(entryDetailsViewModeAtom)

  const [showLongNames, setShowLongNames] = useState(true)

  useEffect(() => {
    setShowLongNames(!entryDetailsOpen || entryDetailsOpen && entryDetailsViewMode === 'drawer')
  }, [entryDetailsOpen, entryDetailsViewMode])

  const handleFocusOnEntry = () => {
    setFocusedItem(entry.id)
    setFocusedStream(entry.stream)
    setFocusedRecord(entry.record)
    setEntryDetailsOpen(true)
  }

  const isSelected = focusedItem === entry.id

  const timezone = useRecoilValue(timezoneAtom)

  const { width: windowWidth } = useWindowDimensions();

  return (
    <Box
      id={entry.key}
      className={`${styles.TrafficEntry} ${
        isSelected ? styles.rowSelected : ''
      }`}
      style={resolveTrafficEntryStyles(entry, focusedItem, focusedStream, headingMode, style)}
      onClick={handleFocusOnEntry}
    >
      {!headingMode && <Protocol
        protocol={entry.proto}
        horizontal={false}
        error={entry.error !== null}
      />}
      <TlsIndicator tls={entry.tls} />

      <Box ml={headingMode ? '10px' : 0}>
        <StatusCode
          statusCode={entry?.status}
          statusQuery={entry.statusQuery}
          protocol={entry.proto.name}
        />
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start' width='100%' pl='10px' mr='10px' sx={{ overflow: 'hidden' }}>
        <Box display='flex' alignItems='center' gap='10px' pt='4px'>
          <Method name={entry.method} query={entry.methodQuery} />
          <Route name={entry.summary} truncateName={!showLongNames && !headingMode} query={entry.summaryQuery} />
        </Box>
        <FullResolutionData entry={entry} showLongNames={showLongNames} headingMode={headingMode} />
      </Box>
      {headingMode && (
        <Box mr='5px'>
          <EntryData
            resource='namespace'
            name={entry.dst.namespace ? `[${entry.dst.namespace}]` : ''}
            query={`dst.namespace == "${entry.dst.namespace}"`}
          />
        </Box>
      )}
      <DuplicateBadge duplicate={entry.duplicate !== ''} duplicateOrigin={duplicateOrigin} />
      <Box display='flex' alignItems='center' gap='5px' mr='15px'>
        <IpWithPort ip={entry.src.ip} port={entry.src.port} point='src' />
        <GenericDirectionIcon fill={entry.proto.backgroundColor} />
        <IpWithPort ip={entry.dst.ip} port={entry.dst.port} point='dst' />
      </Box>
      {(!entryDetailsOpen || windowWidth > 1760) && (
        <>
          <Box width='2px' minWidth='2px' maxWidth='2px' height='50px' bgcolor={variables.dataBackgroundColor}></Box>
          <Timestamp unixTimestamp={entry.timestamp} timezone={timezone} />
        </>
      )}
    </Box>
  )
}
