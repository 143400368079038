import React from 'react'
import { resolveQuery } from '../helpers'
import { Entry, Resolution } from '../../EntryListItem/Entry'
import { EntryData } from './EntryData'

type EntryPoint = 'src' | 'dst'

export const EntrySource: EntryPoint = 'src'
export const EntryDestination: EntryPoint = 'dst'

interface ResolutionDataProps {
  entry: Entry,
  point: EntryPoint
  showLongNames: boolean
}

export const ResolutionData: React.FC<ResolutionDataProps> = ({ entry, point }) => {
  const resolution: Resolution = entry[point]

  return (
    <EntryData
      resource='name'
      name={resolution.name}
      query={resolveQuery(`${point}.name`, '==', resolution.name)}
    />
  )
}
