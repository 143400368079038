import './App.sass';
import React from "react";
import { Header } from "./components/Header/Header";
import { TrafficPage } from "./components/Pages/TrafficPage/TrafficPage";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';
import { useRecoilState } from "recoil";
import scriptingModalOpenAtom from "./recoil/scriptingModalOpen";
import jobsModalOpenAtom from "./recoil/jobsModalOpen";
import { ScriptingModal } from './components/modals/ScriptingModal/ScriptingModal';
import { JobsModal } from './components/modals/JobsModal/JobsModal';

import * as UI from "./components/UI";
import useQueryResolver from './hooks/useQueryResolver';
import useInterceptor from './hooks/useInterceptor';
import { OidcAuthProvider } from './components/UI/Auth/OidcAuth/OidcAuthProvider';
import useLicenseUpdater from './hooks/useLicenseUpdater';
import useAuthTracker from './hooks/useAuthTracker';

const App: React.FC = () => {
  useQueryResolver()
  useInterceptor()
  useLicenseUpdater()
  useAuthTracker()

  const [scriptingModalOpen, setScriptingModalOpen] = useRecoilState(scriptingModalOpenAtom);
  const [jobsModalOpen, setJobsModalOpen] = useRecoilState(jobsModalOpenAtom);

  return (
    <StyledEngineProvider injectFirst>
      <OidcAuthProvider>
        <ThemeProvider theme={createTheme(({}))}>
          <UI.Auth />
          <div className="kubesharkApp">
            <Header />
            <TrafficPage />
            {scriptingModalOpen && <ScriptingModal
              isOpen={scriptingModalOpen}
              onClose={() => setScriptingModalOpen(false)}
            />}
            {jobsModalOpen && <JobsModal
              isOpen={jobsModalOpen}
              onClose={() => setJobsModalOpen(false)}
            />}
          </div>
        </ThemeProvider>
      </OidcAuthProvider>
    </StyledEngineProvider>
  );
}

export default App;
