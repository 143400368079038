import { ColorGreen, ColorRed, ColorWhite } from "../../consts"
import { Entry } from "../EntryListItem/Entry"

import variables from '../../variables.module.scss'

export const resolveTrafficEntryStyles = (entry: Entry, focusedItem: unknown, focusedStream: unknown, headingMode: boolean, style: unknown) => {
  const isSelected = focusedItem === entry.id
  const isTcpSelected = focusedStream === entry.stream

  const borderStyle =
    !headingMode && !isSelected && isTcpSelected ? 'dashed' : 'solid'
  const transparentBorder =
    !headingMode && isTcpSelected ? entry.proto.backgroundColor : 'transparent'
  const backgroundColor = entry.passed
    ? ColorGreen
    : entry.failed
      ? ColorRed
      : ColorWhite

  const standardStyles: React.CSSProperties = {
    border: isSelected
      ? `1px ${entry.proto.backgroundColor} ${borderStyle}`
      : entry.duplicate ?
        `1px ${variables.lighterGrayColor} ${borderStyle}`
        : `1px ${transparentBorder} ${borderStyle}`,
    position: 'absolute',
    top: style['top'],
    marginTop: style['marginTop'],
    width: '100%',
    backgroundColor: entry.duplicate ? variables.lightestGrayColor : backgroundColor,
    minHeight: '52px',
    maxHeight: '52px'
  }

  const headingModeStyles: React.CSSProperties = {
    border: `1px ${transparentBorder} ${borderStyle}`,
    position: 'unset',
    top: style['top'],
    marginTop: '10px',
    width: '100%',
    backgroundColor: backgroundColor
  }

  return headingMode ? headingModeStyles : standardStyles
}

export const resolveQuery = (queryLeftOperand: string, operator: '==' | '>=' | '<=', value: unknown, emptyOnValue = '-') => {
  if (value === emptyOnValue) {
    value = ''
  }

  return `${queryLeftOperand} ${operator} "${value}"`
}
