import React from 'react'

import { Box, Typography, Divider } from '@mui/material'

import styles from './DashboardBanner.module.sass'

import variables from '../../../variables.module.scss'
import logoBackdrop from '../../../assets/logoBackdrop.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const DashboardBanner: React.FC = () => {
  return (
    <Box className={styles.DashboardBanner}>
      <Typography
        variant='h2'
        fontFamily={variables.textFontFamily}
        fontWeight={700}
        color={variables.lightBlueColor}
        style={{ width: '80%' }}
      >
        Live Demo Portal
      </Typography>
      <Typography
        fontFamily={variables.textFontFamily}
        color={variables.mainBackgroundColor}
        style={{ width: '80%' }}
      >
        A live, out-of-the-box deployment of Kubeshark, demonstrating many of its
        available features. <br />

      </Typography>
      <Divider sx={{ width: '50%', backgroundColor: '#6c87fa' }} />
      <Typography
        variant='h4'
        fontFamily={variables.textFontFamily}
        fontWeight={700}
        color={variables.lightBlueColor}
      >
      Demonstrated Features   
      </Typography>
      <ul className={styles.DashboardFeaturesList}>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Real-time API traffic visibility
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            HTTP, REST, gRPC, GraphQL, WebSocket, Redis, RabbitMQ, Kafka and DNS
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Encrypted traffic (TLS)
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Network error detection & analysis
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Identity-aware service map
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Rich query language
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Sensitive data redaction
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Self-hosted with Ingress, TLS & SAML
          </Typography>
        </li>
      </ul>
      <Divider sx={{ width: '50%', backgroundColor: '#6c87fa' }} />
      <Typography
        variant='h4'
        fontFamily={variables.textFontFamily}
        fontWeight={700}
        color={variables.lightBlueColor}
      >
      Disabled Features   
      </Typography>
      <ul className={styles.DashboardFeaturesList}>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Traffic recording & offline analysis
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Namespace-based viewer authorization
          </Typography>
        </li>
        <li>
          <CheckCircleIcon htmlColor='#ffffff' />
          <Typography
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            color={variables.mainBackgroundColor}
          >
            Monitoring & alerts
          </Typography>
        </li>
      </ul>
      <img src={logoBackdrop} className={styles.LogoBackdrop} alt='Logo backdrop' />
    </Box>
  )
}
