import { atom } from 'recoil'
import { LicenseInfo } from '../../types/license'

const defaultLicenseInfo: LicenseInfo = {
  doc: {
    email: '',
    edition: 'community',
    end: new Date().getTime(),
    nodes: 0,
    initialCapacity: 0,
    capacity: 0,
    app_id: '',
    userId: ''
  },
  valid: true,
  maxNodeCount: 0,
  currentNodeCount: 0
}

const licenseInfoAtom = atom({
  key: 'licenseInfoAtom',
  default: defaultLicenseInfo
})

export default licenseInfoAtom
