import React from 'react'

import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';

import variables from '../../../variables.module.scss'

interface DirectionIconProps {
  bgColor: string
}

export const DirectionIcon: React.FC<DirectionIconProps> = ({ bgColor = variables.grayColor }) => {
  return (
    <SwapHorizRoundedIcon htmlColor={bgColor} />
  )
}
