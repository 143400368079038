import React from 'react'

import styles from './NavDropdown.module.sass'
import variables from '../../../variables.module.scss'

import { Menu, MenuItem, Dropdown } from '@mui/base'
import { ClickAwayListener, IconButton, Popper, Grow } from '@mui/material'

import {
  PendingActions as PendingActionsIcon
} from '@mui/icons-material'

import MoreIcon from './MoreIcon'

import { useSetRecoilState } from 'recoil'
import jobsModalOpenAtom from "../../../recoil/jobsModalOpen";

import { LinkAdminConsole } from '../../../consts'
import AdminConsoleIcon from '../../UI/Misc/AdminConsoleIcon'
import { AUTHZ_ACTIONS } from '../../UI/Auth/SamlAuth/Authorization'
import { AuthorizeAction } from '../../UI/Auth/SamlAuth/AuthorizeAction'
import { appAuthEnabled, checkScriptingDisabled } from '../../../types/global'
import ScriptingIcon from '../../UI/Misc/ScriptingIcon'
import scriptingModalOpenAtom from '../../../recoil/scriptingModalOpen/atom'

export const NavDropdown: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleClick = () => {
    setOpen(!open)
  }

  const setScriptingModalOpen = useSetRecoilState(scriptingModalOpenAtom);

  const handleOpenScriptingModal = () => {
    setScriptingModalOpen(true);
  }

  const setJobsModalOpen = useSetRecoilState(jobsModalOpenAtom);

  const handleOpenJobsModal = () => {
    setOpen(false)
    setJobsModalOpen(true);
  }

  const handleOpenAdminPanel = () => {
    setOpen(false)
    window.open(LinkAdminConsole, "_blank")
  }

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    }
  ]

  return (
    <ClickAwayListener onClickAway={() => open && setOpen(false)}>
      <div style={{ position: 'relative' }}>
        <Dropdown open={open}>
          <IconButton
            className={styles.DropdownButton}
            ref={anchorRef}
            onClick={handleClick}
          >
            <MoreIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement='bottom-end'
            transition
            disablePortal
            modifiers={popperModifiers}
            sx={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'right top'
                }}
              >
                <div>
                  <Menu slotProps={{ listbox: { className: styles.Dropdown } }}>
                    {appAuthEnabled() && <MenuItem className={`${styles.DropdownItem} ${checkScriptingDisabled() ? styles.DropdownItemDisabled : ''}`}
                      onClick={handleOpenScriptingModal}
                      disabled={checkScriptingDisabled()}>
                      <ScriptingIcon width={24} height={24} primaryTheme />
                      <span className={styles.DropdownItemText} style={{ marginTop: '2px' }}>Scripting</span>
                    </MenuItem>}
                    <MenuItem className={`${styles.DropdownItem} ${checkScriptingDisabled() ? styles.DropdownItemDisabled : ''}`}
                      onClick={handleOpenJobsModal}
                      disabled={checkScriptingDisabled()}>
                      <PendingActionsIcon htmlColor={checkScriptingDisabled() ? variables.lightGrayColor : variables.blueColor} />
                      <span className={styles.DropdownItemText}>Jobs</span>
                    </MenuItem>
                    <AuthorizeAction action={AUTHZ_ACTIONS.SHOW_ADMIN_CONSOLE_LINK}>
                      <MenuItem className={styles.DropdownItem} onClick={handleOpenAdminPanel}>
                        <AdminConsoleIcon color={variables.blueColor} width={24} height={24} flat />
                        <span className={styles.DropdownItemText}>Admin Console</span>
                      </MenuItem>
                    </AuthorizeAction>
                  </Menu>
                </div>
              </Grow>
            )}
          </Popper>
        </Dropdown>
      </div>
    </ClickAwayListener>
  )
}
