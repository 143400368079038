import { useEffect, useState } from 'react'
import {
  LicenseApiCapacityOption,
  LicenseExpiryOption,
  LicenseInfo
} from '../types/license'
import { useRecoilState } from 'recoil'
import nodeLimitOverflowAtom from '../recoil/nodeLimitOverflow/atom'
import licenseInfoAtom from '../recoil/licenseInfo'
import {
  HubBaseUrl,
  LicenseCommunityEdition,
  LicenseEnterpriseEdition,
  LicenseOndemandEdition,
  LicenseProEdition
} from '../consts'
import { toast } from 'react-toastify'

const defaultLicenseInfo: LicenseInfo = {
  doc: {
    email: '',
    edition: 'community',
    end: new Date().getTime(),
    nodes: 0,
    initialCapacity: 0,
    capacity: 0,
    app_id: '',
    userId: ''
  },
  valid: true,
  maxNodeCount: 0,
  currentNodeCount: 0
}

interface LicenseInfoState {
  ready: boolean
  licenseInfo: LicenseInfo
  nodeLimitOverflow: boolean
  setNodeLimitOverflow: (isOverflow: boolean) => void
  expired: boolean
  valid: boolean
  showApiCapacityProgress: boolean
  apiCapacityDepleted: boolean
  commercialEdition: boolean
  apiCapacityOption: LicenseApiCapacityOption
  expiryOption: LicenseExpiryOption
  resolveCloudLicense: () => Promise<Response>
  getLicense: () => void
}

const useLicense = (): LicenseInfoState => {
  const [licenseRecoil, setLicenseRecoil] = useRecoilState(licenseInfoAtom)

  const [ready, setReady] = useState(false)
  const [licenseInfo, setLicenseInfo] = useState(defaultLicenseInfo)
  const [nodeLimitOverflow, setNodeLimitOverflow] = useRecoilState(
    nodeLimitOverflowAtom
  )
  const [expired, setExpired] = useState(false)
  const [valid, setValid] = useState(true)
  const [showApiCapacityProgress, setShowApiCapacityProgress] = useState(false)
  const [apiCapacityDepleted, setApiCapacityDepleted] = useState(false)
  const [commercialEdition, setCommercialEdition] = useState(false)

  useEffect(() => licenseRecoil.currentNodeCount > 0 && setLicenseInfo(licenseRecoil), [licenseRecoil])

  useEffect(() => {
    if (licenseInfo.currentNodeCount === 0) {
      return
    }

    setReady(licenseInfo.currentNodeCount > 0)
    setNodeLimitOverflow(
      licenseInfo?.currentNodeCount > licenseInfo?.maxNodeCount
    )
    setExpired(
      licenseInfo.doc.end !== 0 && Date.now() > licenseInfo.doc.end
    )
    setValid(licenseInfo.valid && licenseInfo.maxNodeCount > 0)
    setShowApiCapacityProgress(licenseInfo.doc.initialCapacity > 0)
    setApiCapacityDepleted(licenseInfo.doc.capacity < 0)
    setCommercialEdition(
      licenseInfo.doc.edition !== '' &&
      licenseInfo.doc.edition !== LicenseCommunityEdition
    )
  }, [licenseInfo])

  const [apiCapacityOption, setApiCapacityOption] =
    useState<LicenseApiCapacityOption>(LicenseApiCapacityOption.Limited)
  const [expiryOption, setExpiryOption] = useState<LicenseExpiryOption>(
    LicenseExpiryOption.Ends
  )

  // API capacity & expiry behavior for each license edition
  useEffect(() => {
    switch (licenseInfo.doc.edition) {
    case LicenseCommunityEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseProEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Limited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseEnterpriseEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unset)
      setExpiryOption(LicenseExpiryOption.Ends)
      break
    case LicenseOndemandEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    }
  }, [licenseInfo])

  const resolveCloudLicense = (): Promise<Response> => {
    return fetch(`${HubBaseUrl}/license/resolve`, { method: 'POST' })
  }

  const getLicense = () => {
    fetch(`${HubBaseUrl}/license`)
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then((response) => response.json())
      .then((licenseInfo) => {
        setLicenseRecoil(licenseInfo)
      })
      .catch((err) => {
        if (err.length > 0) {
          console.error(err)
          toast.error(err.toString(), {
            theme: 'colored'
          })
        }
      })
  }

  return {
    ready,
    licenseInfo,
    nodeLimitOverflow,
    setNodeLimitOverflow,
    expired,
    valid,
    showApiCapacityProgress,
    apiCapacityDepleted,
    commercialEdition,
    apiCapacityOption,
    expiryOption,
    resolveCloudLicense,
    getLicense,
  }
}

export default useLicense
